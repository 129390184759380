import React, { useEffect, useState } from 'react';
import PageBanner from '../components/banner/PageBanner';
import PageHelmet from '../components/helmet/PageHelmet';
import { useDispatch } from 'react-redux';
import { globalStatistic } from '../redux/actions/otherActions';
import { getPageSeo } from '../redux/actions/pageActions';
import { useTranslation } from 'react-i18next';
import IframeResizer from 'iframe-resizer-react';

const DellPage = () => {

    const dispatch = useDispatch();
    const [pageSeoData, setPageSeoData] = useState({});
    const { t } = useTranslation('dellPage');

    useEffect(() => {
        dispatch(globalStatistic(761, 'PAGE'));
        dispatch(getPageSeo(761)).then(res => {
            if (res) {
                setPageSeoData(res);
            }
        })
    }, [])

    return (
        <>
            {Object.keys(pageSeoData).length !== 0 &&
                <PageHelmet
                    title={`${pageSeoData.acSeoTitle}`}
                    description={`${pageSeoData.acSeoDescription}`}
                    keywords={`${pageSeoData.acSeoKeywords}`}
                    ogDescription={`${pageSeoData.acSeoDescription}`}
                    ogTitle={`${pageSeoData.acSeoTitle}`}
                    ogUrl={`https://ewe.rs/${pageSeoData.acSeoUrl}`}
                    ogImg="https://www.ewe.rs/media/ewe-og-img.jpg"
                    ogType="article"
                    twitterDescription={`${pageSeoData.acSeoDescription}`}
                />
            }
            <IframeResizer
                src={`https://resource.ewe.rs/media/b2b-custom-pages/DELL-5godina-benefita`}
                style={{ minWidth: '1px', width: "100%" }}
            />
        </>
    );
};

export default DellPage;